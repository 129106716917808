<template>
  <main data-aos="fade-up" data-aos-duration="1000">
    <!-- hero-area start -->
    <section class="breadcrumb-bg pt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-9">
            <div class="page-title">
              <h1>Contact Us</h1>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
            <div class="page-breadcumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb ">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Contact</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- hero-area end -->

    <!-- contact-area start -->
    <section class="contact-area pt-120 pb-90">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
            <div class="loader" v-if="loader">
              <img src="../assets/loading-animacion.gif" alt="">
            </div>
            <div v-else>
              <div class="col-12">
                <div class="contact text-center mb-30">
                  <div class="icon-content">
                    <i class="fas fa-envelope"></i>
                  </div>
                  <div class="contact-wrapper">
                    <h3>Mail Here</h3>
                    <p>{{contact_data.email}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-12">
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fhealthtravelsecure&tabs=timeline&width=500&height=550&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
                    width="100%" height="550" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
          </div>
        </div>
      </div>
    </section>
    <!-- contact-area end -->

  </main>
</template>

<script>
    import api from "../services/api";

    export default {
        name: "contact",
        data() {
            return {
                contact_data: [],
                loader: true
            }
        },
        async created() {
            window.scrollTo(0, 0);
            this.loader = true;
            try {
                this.contact_data = await api.getContact();
                this.contact_data = this.contact_data[0];
                this.loader = false;
            } catch {
                this.contact_data = [];
                this.loader = false
            }
        },
    }
</script>

<style scoped>
  .contact-area {
    /*background-image: url("../assets/img/bg/bg-map.png");*/
  }
  .breadcrumb-bg {
    background-image: url("../assets/img/contact-us/contact-us-banner.png");
    position: relative;
  }
  .breadcrumb-bg::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.72);
  }
  .breadcrumb-bg .container {
    position: relative;
    z-index: 2;
  }
  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-content {
    width: 40%;
  }
  .contact-wrapper {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .loader {
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 470px) {
    .contact {
      flex-direction: column;
    }
    .icon-content, .contact-wrapper {
      width: 100%;
    }
  }
</style>
